import { Scene, ShaderMaterial, Renderer, Clock, Uniform, Vector3, Camera, WebGLRenderer } from "three"

export function setupRenderer(): Renderer {
    let renderer = new WebGLRenderer({ alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    console.log(`RENDERER: Pixel Ratio ${window.devicePixelRatio}`)
    console.log(`RENDERER: Size ${window.innerWidth} x ${window.innerHeight}`)

    document.body.appendChild(renderer.domElement);
    return renderer
}

export function startRendering(customWeirdoShader: ShaderMaterial, renderer: Renderer, scene: Scene, camera: Camera) {
    const clock = new Clock()
    function animate() {
        let r = (clock.getElapsedTime() * 100 % 100) / 1000;
        customWeirdoShader.uniforms.iTime.value = clock.getElapsedTime();
        requestAnimationFrame( animate );
        renderer.render(scene, camera);
    }
    animate();
}