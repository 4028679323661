import { PerspectiveCamera, OrthographicCamera, WebGLRenderer, Renderer } from "three";

console.log(`WINDOW: ${window.innerWidth} x ${window.innerHeight}`)

export function setupOrthoCamera(renderer: Renderer): OrthographicCamera {
    const frustumSize = 100;

    const aspect = window.innerWidth / window.innerHeight;

    const left = window.innerWidth / - 2
    const right = window.innerWidth / 2
    const top = window.innerHeight / 2
    const bottom = window.innerHeight / - 2
    const near = 1
    const far = 1000

    const camera = new OrthographicCamera(
        left,
        right,
        top,
        bottom,
        near,
        far
    );

    console.log(`CAMERA: Left ${left}`)
    console.log(`CAMERA: Right ${right}`)
    console.log(`CAMERA: Bottom ${bottom}`)
    console.log(`CAMERA: Top ${top}`)
    console.log(`CAMERA: Near ${near}`)
    console.log(`CAMERA: Far ${far}`)

    window["camera"] = camera
    camera.position.z = 5
    return camera
}

export function setupPerspectiveCamera(): PerspectiveCamera {
    let camera = new PerspectiveCamera(10, window.innerWidth/window.innerHeight, 1, 1000);
    return camera
}