import { Vector2 } from "three";



export function getPlaneSize(imageWidth: number, imageHeight: number, screenWidth: number, screenHeight: number): Vector2 {
    // Clip sides or top?
    let result: Vector2
    if (imageWidth/imageHeight > screenWidth/screenHeight) {
        // clip sides
        result = new Vector2(imageWidth/imageHeight*screenHeight , screenHeight)
    } else {
        // clip top
        result = new Vector2(screenWidth, imageHeight/imageWidth*screenWidth )
    }
    console.log(`PLANE SIZE: ${result.width}, ${result.height}`)
    return result
}

export function getPillScale(planeSize: Vector2, imageWidth: number): number {
    return planeSize.width/imageWidth*8.05
}

export function getLeftPillOffset(planeSize: Vector2, imageWidth: number): Vector2 {
    return new Vector2(planeSize.width/imageWidth*-417.59999666, planeSize.width/imageWidth*215.99999827)
}

export function getRightPillOffset(planeSize: Vector2, imageWidth: number): Vector2 {
    return new Vector2(planeSize.width/imageWidth*571.19999543, planeSize.width/imageWidth*218.39999825)
}