import * as THREE from "three"
import * as dat from "dat.gui"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BoxGeometry, BufferGeometry, DirectionalLight, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, MeshLambertMaterial, Object3D, Raycaster, Scene, ShaderMaterial, Vector2, Vector3, WebGLRenderer } from "three";
import { getLeftPillOffset, getPillScale, getRightPillOffset } from "./sizes";

export function addBox(scene: Scene) {
    const geometry = new BoxGeometry(50, 50, 1);
    const material = new MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new Mesh(geometry, material);
    cube.position.z = -2;
    scene.add(cube);
}

export function addBoxes(scene: Scene) {
    const light = new DirectionalLight(0xffffff, 1);
    light.position.set(1, 1, 1).normalize();
    scene.add(light);

    const geometry = new BoxGeometry(20, 20, 20);

    for (let i = 0; i < 2000; i++) {

        const object = new Mesh(geometry, new MeshLambertMaterial({ color: Math.random() * 0xffffff }));

        object.position.x = Math.random() * 800 - 400;
        object.position.y = Math.random() * 800 - 400;
        object.position.z = Math.random() * 800 - 400;

        object.rotation.x = Math.random() * 2 * Math.PI;
        object.rotation.y = Math.random() * 2 * Math.PI;
        object.rotation.z = Math.random() * 2 * Math.PI;

        object.scale.x = Math.random() + 0.5;
        object.scale.y = Math.random() + 0.5;
        object.scale.z = Math.random() + 0.5;

        scene.add(object);

    }
}

export function lines(scene: Scene) {
    const material = new LineBasicMaterial({ color: 0x0000ff });
    const points: Vector3[] = [];
    points.push(new Vector3(-200, 0, -1));
    points.push(new Vector3(0, 200, -1));
    points.push(new Vector3(200, 200, -1));
    const geometry = new BufferGeometry().setFromPoints(points);
    const line = new Line(geometry, material);
    scene.add(line)

    window["newLine"] = function (x1, y1, x2, y2) {
        const points: Vector3[] = [];
        points.push(new Vector3(x1, y1, -10));
        points.push(new Vector3(x2, y2, -10));
        const geometry = new BufferGeometry().setFromPoints(points);
        const line = new Line(geometry, material);
        scene.add(line)
    }
}

export function addSpeakers(scene: Scene, shaderMaterial: ShaderMaterial, planeSize: Vector2) {
    const loader = new GLTFLoader();
    // Load a glTF resource
    loader.load(
        'left-speaker.gltf',
        function (gltf) {
            (gltf.scene.children[0] as Mesh).material = shaderMaterial;
            gltf.scene.position.z = 2
            let scale = getPillScale(planeSize, 4032)
            console.log(`SCALE ${scale}`)
            gltf.scene.scale.set(scale, scale, 1)
            let position = getLeftPillOffset(planeSize, 4032)
            gltf.scene.position.x = position.x
            gltf.scene.position.y = position.y


            scene.add(gltf.scene);
            window["gltf"] = gltf
        },
        // called while loading is progressing
        function (xhr) {
            console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        // called when loading has errors
        function (error) {
            console.log('An error happened');
        }
    );

    loader.load(
        'right-speaker.gltf',
        function (gltf) {
            (gltf.scene.children[0] as Mesh).material = shaderMaterial;
            gltf.scene.position.z = 2
            let scale = getPillScale(planeSize, 4032)
            console.log(`SCALE ${scale}`)
            gltf.scene.scale.set(scale, scale, 1)
            let position = getRightPillOffset(planeSize, 4032)
            gltf.scene.position.x = position.x
            gltf.scene.position.y = position.y
            scene.add(gltf.scene);
        },
        // called while loading is progressing
        function (xhr) {
            console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        // called when loading has errors
        function (error) {
            console.log('An error happened');
        }
    );


            
}

export function addPlane(scene: Scene, planeSize: Vector2) {
    const texture = new THREE.TextureLoader().load( "bg.jpg" );
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    let material = new THREE.MeshBasicMaterial({map: texture})
    var plane = new THREE.Mesh(new THREE.PlaneGeometry(planeSize.width, planeSize.height),material);
    scene.add(plane);
}