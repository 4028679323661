import * as THREE from "three";
// import * as dat from 'dat.gui';
import { setupRenderer, startRendering } from "./rendering";
import { setupOptions } from "./options";
import { setupOrthoCamera, setupPerspectiveCamera } from "./camera";
import { setupWeirdoShader } from "./shader";
import { addBox, addBoxes, addPlane, addSpeakers } from "./geometry";
import { getPillScale, getPlaneSize } from "./sizes";

// const gui = new dat.GUI();
let scene = new THREE.Scene();
let renderer = setupRenderer()
let shaderMaterial = setupWeirdoShader()
let camera = setupOrthoCamera(renderer)
// let camera = setupPerspectiveCamera()

const light = new THREE.DirectionalLight( 0xffffff, 1 );
light.position.set( 1, 1, 1 ).normalize();
scene.add( light );

const imageWidth = 4032
const imageHeight = 3024

let planeSize = getPlaneSize(imageWidth, imageHeight, window.innerWidth, window.innerHeight)

addPlane(scene, planeSize)
addSpeakers(scene, shaderMaterial, planeSize)


// setupOptions(gui, camera)
startRendering(shaderMaterial, renderer, scene, camera)