import { Uniform, Vector3, ShaderMaterial, Vector2 } from "three";

export function setupWeirdoShader(): ShaderMaterial {
    const col = new Uniform(new Vector3(0, 1, 0))
    const iTime = new Uniform(0.0)
    const resolution = new Uniform(new Vector2(window.innerWidth, window.innerHeight))
    const shaderMaterial = new ShaderMaterial({
        vertexShader: (document as any).getElementById('vertexshader').textContent as string,
        fragmentShader: (document as any).getElementById('fragmentshader').textContent as string,
        uniforms: {
            vColor: col,
            iTime: { value: iTime },
            resolution: resolution
        }
    });
    return shaderMaterial
}